import './forums.css';

function Sprite(props) {
    const {className} = props;

    return(
        <div className='h-auto'>
            <div className='h-40'>
                <p className='font-raleway text-4xl font-extrabold text-gray-50'>Find Sprites!</p>
            </div>
            <div className='h-screen'>
                
            </div>
        </div>
    )
}

export default Sprite;
import './art.css';

export default function Art(props) {
    const {className} = props;

    return(
        <div className='art h-auto text-center'>
            <h1 className='text-blue-50 text-4xl font-bold py-4 font-raleway'>
                Find the art you've been craving for!
            </h1>
        </div>
    )
}
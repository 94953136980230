import logo from './logo.svg';
import './App.css';
import "tailwindcss/tailwind.css";
import { Router } from '@reach/router';
import Menu from './pages/menu/menu.js';
import Sprite from './pages/forums/forums.js';
import Header from './Header.js'
import { useState } from 'react';
import Pricing from './pages/pricing/pricing';
import Art from './pages/Art/art';
import Profile from './pages/Profile/profile';


function App() {
  return (
    <div className="App bg-gray-700" id='app'>
      <div className='index-container' id='index'>
        <Header />
        <main>
            <Router className=''>
                <Menu path='menu' default  />
                <Sprite path='sprite' />
                <Pricing path='pricing'  />
                <Art path='art' />
                <Profile path='profile'/>
            </Router>
        </main>
      </div>
    </div>
  );
}


export default App;

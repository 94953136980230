import Menu from './pages/menu/menu';
import Sprite from './pages/forums/forums';
import { Link } from '@reach/router';
import { useEffect, useState } from 'react';
import Login from './components/login/login';
import Art from './pages/Art/art'
import './Header.css';
import logo from './imitelogo.png';
import { navigate } from '@reach/router';
import { logOut } from './imiteauth.js';



function Header() {

    function enter(event) {
        var evt;
        evt = evt || window.event;
        var charCode = evt.keyCode || evt.which;
        var charStr = String.fromCharCode(charCode);
        console.log(charStr);
    }

    return (
        <nav className='md:flex-col bg-gray-900 nav'>
            <div className='flex'>
                <SiteNavi/>
                <Loginator className="ml-auto" />
            </div>
        </nav>
    )
}

{/* <div className='beta-1.1 changelog'>
    <h1>
        Beta v2:
    </h1>
    {/* <p className='scroll paragraph'>
        Scroll Down to Find More Changlog Content!
    </p> */}
    // <p className='paragraph'>
    //     <br></br>
    //     -Added Auto Friend<br/>
    //     -Added Animated Capes<br/>
    //     -Added Custom Rank on Hypixel<br/>
    //     -Added Chat Mention<br/>
    //     -Added Chat Translate<br/>
    //     -Added FPS BOOSTER <br/>
    //     -Added GUI snapping<br/>
    //     -Added Music Player<br/>
    //     -Added Nick Detector<br/>
    //     <br></br>
    //     -Improved IRC chat<br/>
    //     -Improved User Interface for Configuring Mods<br></br>
    //     -Improved Main Menu<br></br>
    //     <br></br>
    //     -Fixed Crosshair only working on normal GUI scale bug<br/>
    //     -Fixed Mac Crash bug<br/>
    //     -Fixed Optifine Crash bug<br/>
    //     -Fixed Glass bug (cannot rendering glass)<br/>
    //     <br></br>
    //     -Deleted Radio<br/>
    //     -Deleted Skyblock Addons(for later versions)<br/>
    // </p>
    // <i className='arrow down'></i>
{/*</div> */}


function SiteNavi() {
    return (
        <div className=''>
            <ul className='text-left flex text-gray-100 pt-4 pb-4 loink'>
                <a href='/menu' className='flex'>
                    <img src={logo} className='ml-2 mr-2 flex-none'/>
                    <div className="no-underline text-gray-50 text-xl font-bold flex-none">Imite</div>
                </a>
                <Link to='menu' className=' no-underline hover:text-blue-500 pl-6 hover:font-extrabold ' > Menu </Link>
                <Link to='sprite' className=' no-underline hover:text-blue-500 pl-6 hover:font-extrabold ' > Sprites </Link>
                <Link to='art' className=' no-underline hover:text-blue-500 pl-6 hover:font-extrabold ' > Artworks </Link>
                <Link to='pricing' className=' no-underline hover:text-blue-500 pl-6 hover:font-extrabold ' > Purchase </Link>

                {/* <Link to='test' className='  no-underline hover:text-blue-500 pl-6  hover:font-extrabold' > Test </Link> */}
            </ul>
        </div>

    )
}

function Loginator(props) {
    const { className } = props;
    const [isHidden, setIsHidden] = useState('hidden');
    const [hasToken, setHasToken] = useState(null);
    const [username, setUsername] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setHasToken(true)
        } else {
            setHasToken(false)
        }
    }, []);

    function getUsername() {
        const userman = localStorage.getItem('user')
        if (!userman || userman === 'undefined') return null;


        var obj = JSON.parse(userman);

        const email = obj['email'];
    
        return email ? email : 'Guest';
    }

    useEffect(() => {
        const username = getUsername();
        setUsername({username});
    }, [])



    function showLogin() {
        const a = isHidden == 'hidden' ? '' : 'hidden';
        setIsHidden(a)
        console.log('show login')
    }

    const loginElm = (
            <div>
                <button className='bg-gray-900 p-1 hover:text-white object-right text-gray-100 mr-5 mt-2 transition' onClick={showLogin}> Log In </button>
                <button className='bg-gray-100 p-1 rounded-2xl border-double border-4 border-gray-600 text-gray-900 mt-2 transition' onClick={showLogin}> Sign Up</button>
            </div>
    );
    
    const logoutElm = (
        <div className='flex'>
            <Link to='profile ' className='flex-none text-gray-50 no-underline hover:text-blue-500 pt-1 font-bold pl-6 mt-3 hover:font-extrabold ml-auto mr-10' >Profile</Link>
            <button className='bg-green-500 mt-2 flex-none hover:bg-green-600 text-blue-50 hover:text-gray-50 transition btn' onClick={logOut}>Log Out</button>
        </div>
    );

    return (
        <div className={className}>
            <div className='flex ml-auto mr-5 mt-0 mb-0'>
                { hasToken? logoutElm : loginElm }
            </div>
            <Login 
                className={`${isHidden} text-white `} 
                onUserFetch={(user) => {
                    console.log('I got the user', { user })
                    setUser(user)
                    setHasToken(true)
                }}
                preventDefault />
        </div>
    )
}

export default Header;

/**
const p = fetch({
	method: "POST",
	url: "<api endpoint>"
});

p.then((res) => {
	console.log(res)
});

p.catch((error) => {
	console.log(error)
})
*/

// const pwomise = new Promise((resolve, reject) = {
//     setTimeout(()=>{
//         resolve('ez claps')
//         reject("not ez claps")
//     },
//     1000
//     )
    
// })

// pwomise.then(wod=>{
//     console.log('sussy baka uwu')
// }).catch(mommy=>{
//     console.log('suck my dong')
// }).finally(ez=>{
//     console.log('LLLLLL')
// })
import './profile.css';
import { getField } from '../../imiteauth';
import { useEffect } from 'react';


export default function Profile(props) {
    const {className} = props;
    return(
        <div className='h-auto w-full sm:flex'>
            <ContentList/>
            <div className='sm:flex-1'>
                <Sections/>
            </div>
            <div className='sm:w-1/5 bg-gray-800'>
                <p className='text-gray-50 mt-4 font-bold text-2xl text-left ml-4 italic md:not-italic'>
                    Activities
                </p>
                <Messages/>
            </div>
        </div>
    )
}

function Sections() {
    return(
        <div className='one1'>
            <UserInfo className='two2'/>
            <Stats className='three3'/>
        </div>
    )
}

function ContentList(props) {
    const {className} = props;
    return(
        <div className='sm:flex-none h-screen bg-gray-800 sm:w-1/6 lg:w-96'>
            <button className='w-4/6 text-gray-50 font-nm font-lg text-center mt-2 mt-14 focus:text-blue-400 hover:text-blue-400'>
                User Info
            </button>
            <button className='w-4/6 text-gray-50 font-nm font-lg text-center mt-4 focus:text-blue-400 hover:text-blue-400'>
                Dashboard
            </button>
            <button className='w-4/6 text-gray-50 font-nm font-lg text-center mt-2 mt-4 focus:text-blue-400 hover:text-blue-400'>
                Contacts
            </button>
            <p className='w-full text-gray-50 font-bold text-xl text-left ml-6 mt-12'>
                Statistics
            </p>    
            <button className='w-4/6 text-gray-50 font-nm font-lg text-center mt-2 mt-4 focus:text-blue-400 hover:text-blue-400'>
                Earnings
            </button>
        </div>
    )
}

function UserInfo() {
    // useEffect(()=>{
    //     (async () => {
    //         const result = await getField('Jun')
    //         console.log('getting result')
    //         console.log(result)
    //     })()
    // },[])
    return(
        <div className='h-screen w-full'>
            
        </div>
    )
}

function Stats(props) {
    const {className} = props;
    return(
        <div className='h-auto three3 bg-gray-700'>
            <div className='h-screen flex ml-10'>
                <div className='flex-none h-5/6 w-4/6 border border-gray-100 bg-gray-200 mt-12 mb-32 ml-12 p-4 text-gray-900 rounded-lg'>
                    <div className='height'>
                        <button className='urdad ml-8 mr-10 float-left btn bg-gray-100 hover:bg-blue-200 transition text-xs'>
                            24 hours
                        </button>
                        <button className='urdad mr-10 float-left btn bg-gray-100 hover:bg-blue-200 transition text-xs'>
                            7 days
                        </button>
                        <button className='urdad mr-10 float-left btn bg-gray-100 hover:bg-blue-200 transition text-xs'>
                            30 days
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Messages(props) {
    const {className} = props;
    return(
        <div className='m-4 h-auto border border-blue-50 rounded-lg'>
            <div>
                <div className='flex'>
                    <p className='flex-none text-gray-50 text-left pt-2 pl-4'>
                        Offer from: 
                    </p>
                    <p className='flex-none text-gray-50 text-left font-bold pt-2 pl-2'>
                        Sussy baka
                    </p>
                </div>
                    <p className='text-xs text-gray-50 text-left pl-4'> 
                        8 Hours Ago
                    </p>
                </div>
                <div className=''>
                <p className='text-gray-50 text-left pl-4 mt-3 pr-1 text-sm'>
                    "I would like to order five sprites for 5 dollars each plz"
                </p>
            </div>
            <div className=' md:flex mx-4 my-4 md:h-10 sm:h-20 rounded-lg bg-gray-50 '>
                <div className='md:flex text-gray-900 ml-2 pt-2 '>
                    <p className='flex-none'>
                        $5
                    </p>
                    <p className='text-sm flex-none pt-0.5 pl-1.5'>
                        /sprite
                    </p>
                </div>
                <button className=' font-extrabold m-1 ml-4 mr-2  rounded-md w-full  text-sm text-blue-50 bg-green-500 hover:bg-green-600 transition' onClick={() => getField("pfp")}>
                    Accept
                </button>
            </div>
        </div>
    )
}



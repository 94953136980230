import react, { useState, useEffect } from "react";
import './login.css';
import { GoogleLogin } from 'react-google-login';
import { signInWithGoogle, signInWithGithub, signInWithTwitter } from '../../imiteauth.js';
import  { GoogleOutlined, GithubOutlined,  TwitterOutlined} from "@ant-design/icons";

// checkout this document: https://ant.design/components/icon/

export default function Login(props) {
    const className = props.className;
    const onUserFetch = props.onUserFetch;
    const [user, setUser] = useState(null)

    function handleSignInWithGoogle() {
        return signInWithGoogle().then(user => {
            // setUser(user)
            onUserFetch(user);
            localStorage.setItem('user', JSON.stringify(user))
            window.location.reload();
        })
    }
    function handleSignInWithGithub() {
        return signInWithGithub().then(user => {
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user))
            onUserFetch(user);
            // window.location.reload();
        })
    }
    function handleSignInWithTwitter() {
        return signInWithTwitter().then(user => {
            console.log({user})
            // setUser(user);
            onUserFetch(user);
            localStorage.setItem('user', JSON.stringify(user))
            window.location.reload();
        })
    }

    if (!user) {
        return (
            <div className={className + ' sm:h-full h-auto login'}>
                <form className='h-screen ml-auto mr-auto w-3/4 sm:flex'>
                    <div className='flex-1 w-1/2'>
                        <div className='text-left py-2 font-bold'>
                            <p className='text-lg text-blue-50 ' >
                                Type Your Email:
                            </p>
                            <input placeholder='someone@example.com' type='email' className='w-4/5 p-0.5' required />
                        </div>
                        <div className='text-left py-2'>
                            <p className='text-lg text-blue-50 font-bold'>
                                Confirm Your Email:
                            </p>
                            <input placeholder='confirm email' type='email' className='w-4/5 p-0.5' required />
                        </div>
                        <div className='text-left py-2'>
                            <p className='text-lg text-blue-50 font-bold'>
                                Create a Password:
                            </p>
                            <input placeholder='create a password.' type='password' className='w-4/5 p-0.5' required />
                        </div>
                        <div className='text-left py-2'>
                            <p className='text-lg text-blue-50 font-bold'>
                                Create an Username:
                            </p>
                            <input placeholder='create an username.' type='text' className='w-4/5 p-0.5' required />
                        </div>
                    </div>
                    {/* <h1>{tryLogin("shadowgenjiftw@gmail.com", "test")}</h1> */}
                    <div className='flex-1 w-1/2'>
                        <div className='object-right py-2 border border-gray-50 rounded-lg mt-6'>
                            <p className='text-blue-50 text-center font-bold text-lg pl-4'>
                                Or You can Sign in With:
                            </p>
                            <button onClick={handleSignInWithGoogle} className='bg-blue-400 hover:bg-blue-500 mr-5 transition btn text-gray-900'><GoogleOutlined /></button>
                            <button onClick={handleSignInWithGithub} className='bg-blue-400 hover:bg-blue-500 transition btn text-gray-900'><GithubOutlined /></button>
                            <button onClick={handleSignInWithTwitter} className='bg-blue-400 hover:bg-blue-500 ml-5 transition btn text-gray-900'><TwitterOutlined /></button>
                        </div>
                    </div>
                </form>
            </div>
        )
    } else {
        return null;
    }
}


// [ ] => |

// keyframe
// start keyframe: [ ]     width: 20px;
// end keyframe: |         width: 1px;   <= hover
// transition: width 200ms ease-in-out


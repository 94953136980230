import Sprite from '../forums/forums';
import './menu.css';
import {navigate} from '@reach/router';



function Menu(props) {

    const {className} = props;
    return(
        <div className=' relative menu-container text-gray-50 h-auto'>
            <div className='containerboi mb-20 '>
                <h1 className='h1 text-5xl pt-6 pb-3'>
                    Welcome, Developer!
                </h1>
                <div className='pg-0 bg-gray-600 rounded-lg text-center pt-4 pb-8'>
                    <p className='text-3xl text-blue-50'>
                        Packs Of The Day!
                    </p>
                    <div className='border border-gray-100'>

                    </div>
                    <div className='h-5/6 mt-4 border border-gray-50'> 
                        <div className='w-full md:w-1/3 md:h-full h-1/2 border border-gray-900 rounded-lg bg-gray-800 pp font-sand font-bold relative'>
                            <div className='bg-gray-900 three h-full w-full'>
                                
                            </div>
                            <div className='two h-full '>
                                <p className=' text-lg text-gray-100'>
                                    Sprite No.1
                                </p>
                                <button className='gae bg-gray-700 btn my-auto hover:bg-gray-800 button-click mr-5 mb-16 opacity-80 '>
                                    Contact
                                </button>
                                <button className='bg-gray-700 hover:bg-gray-800 button-click mr-5 mb-4 btn my-auto gae opacity-80 '>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className=' md:pt-2'>
                        <p className='font-bold'>Learn More</p>
                        <i className="arrow down motion-safe:animate-spin"></i>
                    </div>
                </div>
                <div className='md:flex text-center mt-8 ml-8 mr-8 mb-8 pb-6 '>
                    <div className=' pg-1 w-full md:w-1/2 cardHeight bg-gray-900 rounded-lg md:rounded-xl mt-auto mb-auto'>
                        <p className='text-gray-100 text-center md:text-left p-5 sm:ml-5 sm:mr-5 text-xs '>
                            Sprites
                        </p>
                        <p className='text-gray-50 text-center md:text-left font-bold  sm:ml-10 sm:text-3xl md:text-4xl text-xl sm:font-black font-sand'>
                            Find the best sprites available
                        </p>
                        <div className='md:text-left text-center pt-4 md:ml-4 sm:ml-10 text-sm sm:text-lg  font-sand font-semibold'>
                            <p className='text-blue-50'>
                                Imite is the best website for loyalty-free Sprite Libraries.<br/>
                                Find the Sprite that fits the best for your project!
                            </p>
                        </div>
                        <div className='group md:float-left mt-5 md:ml-10'>
                            <button className='btn button-click bg-blue-800 group-hover:bg-blue-400' onClick={() => {
                                navigate('/sprite')
                            }
                            }>
                                <p className='text-blue-100 group-hover:text-blue-900'>Find Sprites</p>
                            </button>
                        </div>
                    </div>
                    <div className='text-gray-50 md:w-2/5 mt-auto mb-auto  h-1/3 md:h-2/3 '> images placeholder </div>

                </div>
                {/* <div className="md:flex mx-8 justify-between pb-12 boi  shadow-inner">
                    <div className='text-gray-50 mr-6 md:w-2/5 mt-auto mb-auto h-2/3 md:h-2/3'> images placeholder </div>
                    <div className='w-full md:w-1/2 lg:h-2/3 h-1/2  object-right bg-gray-900 rounded-lg pg-1 text-center height-50 mt-auto mb-auto'>
                        <p className='text-gray-100 text-center md:text-right p-5 mr-5 text-xs'>
                            Artworks
                        </p>
                        <p className='text-gray-50 text-center md:text-right mr-10 text-xl sm:text-3xl md:text-4xl font-black font-sand'>
                            Discover the artworks!
                        </p>
                        <div className='text-center md:text-right text-sm pt-4 ml-4 mt-4 md:mr-10 sm:text-lg pr-3'>
                            <p className='text-blue-50  font-sand font-semibold'>
                                Imite also provides a variety of fresh and original artwork for you to choose from.
                                Start exploring the different kinds of drawings ready for purchase!
                            </p>
                        </div>
                        <div className='group md:float-right mt-5 mr-10 '>
                            <button className='btn button-click bg-blue-800 group-hover:bg-blue-400' onClick={() => {
                                navigate('/art')
                            }
                            }>
                                <p className='text-blue-100 group-hover:text-blue-900'>Explore Art Packs</p>
                            </button>
                        </div>
                    </div>
                </div> */}
                <div className='md:flex mx-8 shadow-md h-12 bg-gray-900 rounded-lg md:rounded-xl'>
                </div>
            </div>
            <div className='h-40 bg-gray-900 w-full'>
                <div className=' w-48 h-28'>
                    <a href="https://discord.gg/cZV9qQRyU2" target="_blank">
                        <button className='btn-success button-click bg-green-500 hover:bg-green-400 mt-12 float-right'>
                            Join Our Discord~
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default Menu;
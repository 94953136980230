import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, GithubAuthProvider, TwitterAuthProvider } from "firebase/auth";
import { toURLParams } from "./components/tools/tools";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOlihlTVQcBPneUE_NnDkGIGmns_-YKI0",
  authDomain: "imite-api.firebaseapp.com",
  projectId: "imite-api",
  storageBucket: "imite-api.appspot.com",
  messagingSenderId: "93078008105",
  appId: "1:93078008105:web:341fecc3820af7e83ec738",
  measurementId: "G-0G3MDZY8M1"
};

//SUS
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const GoogleProvider = new GoogleAuthProvider();
GoogleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const auth = getAuth();
auth.languageCode = 'it';
GoogleProvider.setCustomParameters({
  'login_hint': 'user@example.com'
});



export function signInWithGoogle() {
  return signInWithPopup(auth, GoogleProvider).then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.log(user);
    saveAuth(user);
    return user
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
}
//console.log(document.cookie);
const GitHubProvider = new GithubAuthProvider();

GitHubProvider.addScope('repo');
//sus
GitHubProvider.setCustomParameters({
  'allow_signup': 'false'
});

export function signInWithGithub() {
  return signInWithPopup(auth, GitHubProvider)
    .then((result) => {
      console.log("SUSSSSSSS");
      // This gives you a GitHub Access Token. You can use it to access the GitHub API.
      const credential = GithubAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;
      console.log(user);
      console.log(token);
      saveAuth(user);
      return user

      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GithubAuthProvider.credentialFromError(error);
      // ..
    });
}

const twitterAuthProvider = new TwitterAuthProvider();

export function signInWithTwitter() {
  return signInWithPopup(auth, twitterAuthProvider)
    .then((result) => {
      // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
      // You can use these server side with your app's credentials to access the Twitter API.
      const credential = TwitterAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const secret = credential.secret;

      // The signed-in user info.
      const user = result.user;
      saveAuth(user);
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = TwitterAuthProvider.credentialFromError(error);
      // ...
    });
}

export function saveAuth(user) {
  localStorage.setItem("token", user.accessToken);
  localStorage.setItem("user", JSON.stringify(user));
}

export function logOut() {
  console.log('logging out')
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.reload();
}

export function imiteAPI() {
  const key = ''

}

export async function getField(garfield) {
  const accesstoken = localStorage.getItem("accessToken");

  const post = await post('https://imite-api.herokuapp.com/users/getting/field', {
    field: garfield,
    token: accesstoken
  });
  console.log(post);
  return post;
}

async function post(url = '', data = {}) {
  const res = await fetch(url, {
    method: 'POST',
    mode:'no-cors',
    headers: {
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(data)
  }).then((ret) => {
    console.log("NUT")
    console.log(ret);
    console.log("ENDNUT")
  })
  return res;
}


async function get(url='', data={}) {
  const res = await fetch(url, {
    body: data
  })
}


import Login from '../../Header';
import {useState} from 'react';

export default function Pricing(props){
  const {className} = props;
    const [isHidden, setIsHidden] = useState('hidden');

    function showLogin() {
      const a = isHidden === 'hidden'? '' : 'hidden';
      setIsHidden(a)
      console.log('show login')
    }
    return (
        <div className="w-full bg-blue pt-12  md:h-auto ">
          <div className="flex flex-col sm:flex-row justify-center mb-6 sm:mb-0">
            <div className="sm:flex-1 lg:flex-initial lg:w-1/4 rounded-lg rounded-tr-none bg-white mt-4 flex flex-col">
            <div className="w-full p-8 text-3xl font-bold text-center">Newbie</div>
              <div className="w-full border-0 border-grey-light border-t border-solid text-sm">
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  2 Free Featured Sprites / Artwork Per Month
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  Freedom of Publishing Sprites and Artwork Packs 
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  50% deduction of profits of published Sprites and Art Packs
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4 line-through">
                  10 coins per month
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4 line-through">
                  Analytics
                </div>
              </div>
              <div className="w-full text-center px-8 pt-8 text-xl mx-auto mt-10">
                Free
              </div>
              <div className="text-center pt-8 mb-8 mt-auto">
                <button class="inline-block border border-gray-900 hover:bg-gray-100 bg-black text-black px-6 py-4 rounded" href="#">Sign Up</button>
              </div>
            </div>
            <div className="flex-1 lg:flex-initial lg:w-1/4 rounded-lg bg-white mt-4 sm:-mt-4 shadow-lg z-30 flex flex-col">
              <div className="w-full p-8 text-3xl font-bold text-center">Basic</div>
              <div className="w-full border-0 border-grey-light border-t border-solid text-sm">
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  10 Free Featured Sprites / Artwork per Month
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  Freedom of Publishing Sprites and Artwork Packs
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  <span className='line-through'>
                    50%
                  </span>
                  <p>
                    40% deduction of profits of published Sprites and Art Packs
                  </p>
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4 ">
                  <div className="line-through">30 coins per month</div>50 coins per month
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4 line-through">
                  Analytics
                </div>
              </div>
              <div className="w-full text-center px-8 pt-8 text-xl mt-auto">
                <span className="text-grey-light italic line-through">
                  $8.99 per month
                </span>
                <p className='text-extrabold'>
                  $4.99 per month
                </p>
              </div>
              <div className="text-center pt-8 mb-8 mt-auto">
                <button class="inline-block border border-gray-900 hover:bg-gray-100 bg-black text-black px-6 py-4 rounded" href="#">Sign Up</button>
              </div>
            </div>
            <div className="flex-1 lg:flex-initial lg:w-1/4 rounded-lg rounded-tl-none bg-white mt-4 flex flex-col">
              <div className="p-8 text-3xl font-bold text-center">Pro</div>
              <div className="border-0 border-grey-light border-t border-solid text-sm">
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  Unlimited Featured Sprites / Artwork per month
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                Freedom of Publishing Sprites and Artwork Packs
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                20% deduction from profits of published Sprites and Art Packs
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                <div className="line-through">100 coins per month</div>150 coins per month
                </div>
                <div className="text-center border-0 border-grey-light border-b border-solid py-4">
                  Analytics
                </div>
              </div>
              <div className="text-center px-8 pt-8 text-xl mt-auto">
                <span className="text-grey-light italic line-through">
                  $19.99 per month
                </span>
                <p className='text-extrabold'>
                  $15.99 per month
                </p>
              </div>
              <div className="text-center pt-8 mb-8 mt-auto">
                <button class="inline-block border border-gray-900 hover:bg-gray-100 bg-black text-black px-6 py-4 rounded" href="#">Sign Up</button>
              </div>
            </div>
          </div>
          <a href="" class="text-left pl-8"><h2 class="text-blue-500 underline">Dont want a subscription? Buy coins!</h2></a>
        </div>
    )
}